<template>
  <div class="schedule-toggle">
    <button @click="toggleSchedule">
      Switch to {{ nextSchedule }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentSchedule: {
      type: String,
      required: true
    }
  },
  computed: {
    nextSchedule() {
      return this.currentSchedule === 'highSchool' ? 'Middle School' : 'High School';
    }
  },
  methods: {
    toggleSchedule() {
      const newSchedule = this.currentSchedule === 'highSchool' ? 'middleSchool' : 'highSchool';
      this.$emit('change:schedule', newSchedule); // Emitting a more explicitly named event
      localStorage.setItem('currentSchedule', newSchedule);
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');
.schedule-toggle {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

button {
  font-family: 'Roboto Mono', monospace;
  padding: 8px 16px;
  border: 2px solid #ccc;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  background-color: white;
  transition: all 0.3s ease;
  outline: none;
}

button:hover {
  border-color: #888;
  color: #666;
}

button:active {
  background-color: #f7f7f7;
}
</style>
