import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/Parent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/score',
    name: 'score',
    component: () => import('../components/HeadmastersDay.vue')
  },
  {
    path: '/connections',
    name: 'connections',
    beforeEnter: () => {
      // Redirecting to the external URL
      window.location.href = 'https://custom-connections-game.vercel.app/b3stz7PH5Cyu2jDnlCzp';
    }
  },
  {
    path: '/poll',
    name: 'President',
    component: () => import('../components/PollComponent.vue')
  },
  {
    path: '/vote',
    name: 'Vote',
    component: () => import('../components/Vote.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
