<template>
  <div class="construction-background">
    <div class="overlay-container">
      <!-- Countdown clock -->
      <div class="countdown-clock">
        {{ countdown.minutes }}:{{ countdown.seconds < 10 ? '0' + countdown.seconds : countdown.seconds }}
      </div>
      <!-- Your additional content goes here -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionBackground',
  data() {
    return {
      countdown: {
        minutes: 0,
        seconds: 0
      },
      targetTime: new Date('2024-10-01T09:35:00').getTime(), // Set the target date and time
      countdownInterval: null
    };
  },
  created() {
    this.updateCountdown();
    this.countdownInterval = setInterval(this.updateCountdown, 1000); // Update every second
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval); // Clear the interval when component is destroyed
  },
  methods: {
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.targetTime - now;

      if (distance > 0) {
        this.countdown.minutes = Math.floor(distance / (1000 * 60));
        this.countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      } else {
        // If the countdown reaches zero, stop updating
        this.countdown.minutes = 0;
        this.countdown.seconds = 0;
        clearInterval(this.countdownInterval);
      }
    }
  }
};
</script>

<style scoped>
/* Construction background container */
.construction-background {
  position: relative;
  min-height: 100vh;
  background-color: #f0f0f0; /* Optional fallback background color */
  background-size: cover;
  overflow: hidden; /* Ensure nothing overflows out of the container */
}

/* Overlay content */
.overlay-container {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Take up full screen height */
  text-align: center;
}

/* Countdown clock styling */
.countdown-clock {
  font-size: 20vw; /* Scale the font based on the viewport width */
  font-weight: bold;
  color: #F96302;
  font-family: sans-serif; /* Block letter style */
  text-align: center;
  line-height: 1; /* Adjust line height for proper centering */
  text-transform: uppercase; /* Make the text uppercase */
  
  /* New box outline */
  border: 10px solid #F96302; /* Orange outline */
  padding: 10px; /* Add space between text and border */
  box-sizing: border-box; /* Ensure padding is included in the element’s total size */
}

/* Add the first caution tape strip (from top-left to bottom-right) */
.construction-background::after {
  content: '';
  position: absolute;
  top: -50px;
  left: -720px; /* Ensure it starts off-screen */
  width: 300%; /* Increased width to cover the entire screen */
  height: 40px; /* Increased height for a thicker tape */
  background: repeating-linear-gradient(
    45deg,
    black 0,
    black 30px,
    #FFD700 30px,
    #FFD700 60px
  ); /* Black and yellow caution tape pattern */
  transform: rotate(-45deg); /* Diagonal across the screen */
  z-index: 3; /* Ensure it appears on top of content */
}

/* Add the second caution tape strip (from top-right to bottom-left) */
.construction-background::before {
  content: '';
  position: absolute;
  top: 50px;
  left: -1700px; /* Ensure it starts well off-screen */
  width: 300%; /* Increased width to cover the entire screen */
  height: 40px; /* Increased height for a thicker tape */
  background: repeating-linear-gradient(
    45deg,
    black 0,
    black 30px,
    #FFD700 30px,
    #FFD700 60px
  ); /* Black and yellow caution tape pattern */
  transform: rotate(45deg); /* Diagonal across the screen */
  z-index: 3; /* Ensure it appears on top of content */
}

/* Hide caution tapes on screens smaller than 768px */
@media (max-width: 768px) {
  .construction-background::after,
  .construction-background::before {
    display: none;
  }
  .countdown-clock {
    font-size: 25vw; /* Increase font size on smaller screens for readability */
  }
}

@media (max-width: 480px) {
  .countdown-clock {
    font-size: 30vw; /* Further increase font size for very small screens */
  }
}
</style>
